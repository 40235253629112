import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, Typography } from "@mui/material";
import { DrawingExtractOptions } from '../../types/drawing';
import { Button } from '../shared/Button';
import { ExtractsCheckbox } from '../../styles';

const getMarkupName = (optionName: string): string => {
  switch (optionName) {
    case "include_correspondence":
      return "Correspondence";

    case "include_rfis":
      return "RFIs";

      case "include_documents":
      return "Documents";

    case "include_inspections":
      return "Inspections";

    case "include_observations":
      return "Observations";

    case "include_photos":
      return "Photos";

    case "include_punch_items":
      return "Punch Items";

    case "include_sketches":
      return "Sketches";

    case "include_submittals":
      return "Submittals";

    case "include_sheet_links":
      return "Include Sheet Links";

    case "include_other_markup":
      return "Other Markup";

    default:
      return "";
  }
}

const DrawingOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  const drawingOptions = toolTabData.extractOptions as DrawingExtractOptions;
  const [markupOptions, setMarkupOptions] = React.useState({
    // include_correspondence: drawingOptions.include_correspondence,
    include_documents: drawingOptions.include_documents,
    // include_inspections: drawingOptions.include_inspections,
    // include_observations: drawingOptions.include_observations,
    include_other_markup: drawingOptions.include_other_markup,
    include_photos: drawingOptions.include_photos,
    // include_punch_items: drawingOptions.include_punch_items,
    include_rfis: drawingOptions.include_rfis,
    include_sketches: drawingOptions.include_sketches,
    include_sheet_links: drawingOptions.include_sheet_links,
    // include_submittals: drawingOptions.include_submittals,
  } as Record<string, boolean>);

  React.useEffect(() => {
    dispatch({
      type: 'SET_TOOL_OPTIONS',
      value: {
        toolTabIndex: toolTabIndex,
        option: markupOptions as DrawingExtractOptions
      }
    });
  }, [markupOptions]);

  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h3>Public Markups to Include</h3>
      <Button
        onClick={() => {
          if (Object.values(markupOptions).some(t => t)) {
            const options = Object.keys(markupOptions).reduce((acc, current) => {
              acc[current] = false;

              return acc;
            }, {} as Record<string, boolean>);

            setMarkupOptions(options);
          } else {
            const options = Object.keys(markupOptions).reduce((acc, current) => {
              acc[current] = true;

              return acc;
            }, {} as Record<string, boolean>);

            setMarkupOptions(options);
          }
        }}
        variant="outlined"
        disableElevation={true}
        size="medium"
      >
        {Object.values(markupOptions).some(t => t) ? "Deselect All" : "Select All"}
      </Button>
      {
        Object.keys(markupOptions).map(key => {
          return (
            <Box key={`drawing-option-container-${key}`} display="flex" flexDirection="row" alignItems={"center"} marginTop={"18px"}>
              <ExtractsCheckbox
                key={`drawing-option-${key}`}
                disableRipple
                sx={{
                  padding: 0
                }}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={markupOptions[key]}
                onChange={() => {
                  setMarkupOptions({
                    ...markupOptions,
                    [key]: !markupOptions[key]
                  });
                }}
                style={{ marginRight: 8 }}
              />
              <Typography>
                {getMarkupName(key)}
              </Typography>
            </Box>
          );
        })
      }
    </Box>
  )
};

export default DrawingOptions;